/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  {
    
    description:
      'Class Assembly 4A',
    href : '/class-assembly-4a-2024'
 
  },
  {
    
    description:
      'Class Assembly 4B',
    href : '/class-assembly-4b-2024'
 
  },
  {
    
    description:
      'Class Assembly 4C',
    href : '/class-assembly-4c-2024'
 
  },
  {
    
    description:
      'Class Assembly 5A',
    href : '/class-assembly-5a-2024'
 
  },
  {
    
    description:
      'Class Assembly 5B',
    href : '/class-assembly-5b-2024'
 
  },
  {
    
    description:
      'Class Assembly 5C',
    href : '/class-assembly-5c-2024'
 
  },
  {
    
    description:
      'Class Assembly 5D',
    href : '/class-assembly-5d-2024'
 
  },
  {
    
    description:
      'Class Assembly 5E',
    href : '/class-assembly-5e-2024'
 
  },
  {
    
    description:
      'Class Assembly 5F',
    href : '/class-assembly-5f-2024'
 
  },
  {
    
    description:
      'Class Assembly 8A',
    href : '/class-assembly-8a-2024'
 
  },
  {
    
    description:
      'Class Assembly 8B',
    href : '/class-assembly-8b-2024'
 
  },
  {
    
    description:
      'Class Assembly 8C',
    href : '/class-assembly-8c-2024'
 
  },
  {
    
    description:
      'Class Assembly 8D',
    href : '/class-assembly-8d-2024'
 
  },
   {
    
    description:
      'Class Assembly 10A',
    href : '/class-assembly-10a-2024'
 
  },
  {
    
    description:
      'Class Assembly 10B',
    href : '/class-assembly-10b-2024'
 
  },
  {
    
    description:
      'Class Assembly 10C',
    href : '/class-assembly-10c-2024'
 
  },
  {
    
    description:
      'Class Assembly 10D',
    href : '/class-assembly-10d-2024'
 
  },
   {
    
    description:
      'Class Assembly 10E',
    href : '/class-assembly-10e-2024'
 
  },
  {
    
    description:
      'Class Assembly 12A',
    href : '/class-assembly-12a-2024'
 
  },
  {
    
    description:
      'Class Assembly 12B',
    href : '/class-assembly-12b-2024'
 
  },
  {
    
    description:
      'Class Assembly 12C',
    href : '/class-assembly-12c-2024'
 
  },
 
 

];

const ClassAssembly2024 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Class Assembly 2024-25
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ClassAssembly2024;